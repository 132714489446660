import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Generator from '../components/Generator';

const PageTemplate = ({ data }) => (
	<Layout>
		<Seo />
		<Generator components={data?.datoCmsTemplatePage?.components} />
	</Layout>
);

export default PageTemplate;

export const PageTemplateQuery = graphql`
	query PageTemplateQuery($id: String!) {
		datoCmsTemplatePage(id: { eq: $id }) {
			id
			slug
			metaTags {
				title
				description
			}
			components {
				... on DatoCmsComponentHero {
					id
					internal {
						type
					}
					headingKicker
					heading
					subhead
					primaryButton {
						buttonType
						variant
						download
						asset {
							url
						}
						to
						label
					}
					secondaryButton {
						buttonType
						variant
						download
						asset {
							url
						}
						to
						label
					}
					image {
						image {
							alt
							gatsbyImageData
						}
					}
				}
				... on DatoCmsComponentCardDeck {
					id
					internal {
						type
					}
					background
					headingKicker
					heading
					cards {
						... on DatoCmsEntityService {
							id
							internal {
								type
							}
							internalName
							description
							icon {
								iconType
								icon
							}
						}
						... on DatoCmsEntityProject {
							id
							internal {
								type
							}
							internalName
							siteUrl
							githubRepo
							techStack
							description
							featuredImage {
								image {
									alt
									gatsbyImageData
								}
							}
							images {
								image {
									alt
									gatsbyImageData
								}
							}
						}
					}
				}
				... on DatoCmsComponentSwitchback {
					id
					internal {
						type
					}
					sectionType
					headingKicker
					heading
					subhead
					featuredContent {
						... on DatoCmsEntityImage {
							id
							image {
								alt
								gatsbyImageData
							}
						}
						... on DatoCmsEntityForm {
							id
							internalName
						}
					}
					icons {
						id
						internalName
						iconType
						icon
					}
				}
			}
		}
	}
`;
